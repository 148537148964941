

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { itemHoverConfig } from '@/services/Factories/Item/ItemFactory'

@Component
export default class ItemIconCard extends Vue {
  @Prop() item

  itemHoverConfig = itemHoverConfig

  get isGMRoute() {
    return this.$route.fullPath.includes('gm')
  }

  viewItemOnItemViewer() {
    const { isEquipped, emptySlot } = this.item

    return isEquipped && emptySlot ? this. $root.$emit('setItemOnItemDetailsViewer', this.item) : null
  }
}
